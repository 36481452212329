.arabicDirection{
    direction: rtl;
    & .logo{
        padding-right: 0 !important;
        margin-right: 0 !important;
        padding-left: 32px;
        margin-left: 32px;
        border-right: unset !important;
        border-left: 1px solid #6C7275;
        @media (max-width: 1220px) {
            padding-left: unset;
            margin-left: unset;
            border-left: unset;
        }
    }
}
.customerFooterWrapper {
    width: 100%;
    margin: 0 auto;
    /* max-width: 1228px; */
    padding: 80px 80px 30px;
    background-color: #FFD046;
    border-radius: 0px 0px 8px 8px;

    @media(max-width: 768px) {
        padding: 80px 40px 30px;
    }

    @media(max-width: 600px) {
        padding: 80px 15px 30px;
    }

    & .footerBox {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 50px;
        border-bottom: 0.5px solid #6C7275;

        @media(max-width: 1220px) {
            gap: 20px;
            flex-wrap: wrap;
            flex-direction: column;
        }

        & .footerTopLeft {
            display: flex;

            @media(max-width: 1220px) {
                flex-wrap: wrap;
                flex-direction: column;
                align-items: center;
                gap: 20px;
            }

            & .logo {
                padding-right: 32px;
                margin-right: 32px;
                border-right: 1px solid #6C7275;

                @media(max-width: 1220px) {
                    border-right: 0;
                    margin-right: 0;
                    padding-right: 0;
                }

                & svg {
                    width: 60px;
                    height: 60px;
                    & path {
                        fill: #000;
                    }
                }
            }

            & .bookingDeals {
                color: #000;
                font-size: 14px;
                font-weight: 400;
                line-height: 22px;

                @media(max-width: 600px) {
                    text-align: center;
                }
            }
        }

        & .footerTopRight {
            @media(max-width: 768px) {
                gap: 20px;
                display: flex;
            }

            @media(max-width: 600px) {
                flex-wrap: wrap;
                flex-direction: column;
                justify-content: center;
            }

            & a,
            span {
                color: #000;
                font-size: 14px;
                font-weight: 400;
                line-height: 22px;
                margin-left: 40px;

                @media(max-width: 768px) {
                    margin-left: 0;
                }

                @media(max-width: 600px) {
                    text-align: center;
                }
            }
        }
    }

    & .footerBottom {
        display: flex;
        padding: 16px 0;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;

        @media(max-width: 920px) {
            gap: 20px;
            justify-content: center;
        }

        @media(max-width: 600px) {
            flex-direction: column;
        }

        & .footerBottomLeft {
            display: flex;
            align-items: center;

            @media(max-width: 600px) {
                gap: 15px;
                flex-wrap: wrap;
                flex-direction: column;
                justify-content: center;
            }

            & .leftText {
                font-size: 12px;
                font-weight: 400;
                line-height: 20px;
                color: #000;
                margin-right: 28px;

                @media(max-width: 600px) {
                    margin-right: 0;
                }
            }
        }

        & .footerBottomRight {
            @media(max-width: 600px) {
                display: flex;
                gap: 12px;
            }
            & a,
            span {
                margin-left: 24px;

                @media(max-width: 600px) {
                    margin-left: 0;
                }

                & svg {
                    & path {
                        fill: none;
                    }
                }
            }
        }
    }
}