.link {
  & .imageWrapper {
    height: 232px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ddd;
    position: relative;

    @media (--viewportMedium) {
      height: 204px;
    }

    & .onproduct {
      position: absolute;
      top: 10px;
      right: 12px;
      margin: 0;
      padding: 4px 10px;
      font-size: 16px;
      color: #fff;
      background: #b06b5d;
      border-radius: 45px;
    }
  }
}
.arabicDirection{
  direction: rtl;
  & .priceWrapper{
    text-align: right !important;
  }
  & .likeIconWrapper{
    right: unset !important;
    left: 8px;
  }
  & .wishListIcon{
    right: unset !important;
    left: 8px;
  }
}
.link {
  &:hover {
    text-decoration: unset;
  }
}

.openListingButton {
  background-color: #dfeafd;
  height: 41px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #4472b7;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  /* 123.077% */
  letter-spacing: -0.2px;
  /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 10px;
  margin-bottom: 8px;

  @media (--viewportMedium) {
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    margin-top: 10px;
    margin-bottom: 14px;
  }
}

.category {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  align-items: flex-start;
  padding: 0;

  margin-top: 20px;
  margin-bottom: 0px;
  min-height: 80px;

  &>span {
    border-radius: 20px;
    color: #4472b7;
    font-family: Poppins;
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 1px;

    color: #2c2c2c;
    padding: 6px 22px;
    border: 2px solid #e4e1df;
    max-width: calc((100% - 8px) / 2);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.root {
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 100%;
}

.cardFooter {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 3px;
  flex: 1;

  @media (--viewportMedium) {}
}

.buttonWrapper {
  margin-top: 12px;
}

.buttonWrapper {
  & button {
    border: 2px solid #FFD046;
    font-size: 17px;
    font-weight: 600;
    line-height: 22px;
    text-align: center;
    color: #000;
    width: fit-content;
    min-height: 0;
    background-color: #ffffff;
    border-radius: 45px;
    padding: 5px 24px;
  }
}

.linkbuyNow {
  color: #000;
  text-align: center;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  /* 142.857% */
  letter-spacing: 2px;

  &:hover {
    text-decoration: none;
  }
}

.emptySpace {
  flex: 1;
}

.likeIcon {
  border-radius: 9999px;
  /* background: var(--Sand---Primary, #efe4d3); */
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 10px;
  cursor: pointer;
  left: 10px;
}

.priceWrapper {
  color: #000;
  text-align: center;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  /* 142.857% */
  letter-spacing: 2px;
  white-space: pre;
}

.readStatus {
  cursor: pointer;
}

.bagButton {
  /* width: 73.09px; */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: var(--Sand---Primary, #efe4d3);
  color: var(--Black, #000);
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
  height: 26px;

  @media (--viewportLarge) {
    height: 33px;
  }

  &>span {
    padding: 2px 9.7px 2px 10px;

    @media (--viewportLarge) {
      padding: 4px 9.7px 4px 10px;
    }

    cursor: pointer;
    gap: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

/* HTML: <div class="loader"></div> */
.loader {
  width: 15px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 2px solid;
  border-color: #fff #fff;
  animation: l1 1s infinite;
}

@keyframes l1 {
  to {
    transform: rotate(0.5turn);
  }
}

.modalRemove {
  &>div {
    &>div {
      flex-basis: 448px !important;

      &>button {
        display: none !important;
      }
    }
  }
}

.deleteIcon {
  height: 72px;
  width: 72px;
  fill: #000;
}

.modalContent {
  text-align: center;

  & .modalTitle {
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin: 0;
    line-height: 1.75rem;
    font-size: 1.25rem;
    font-weight: 700;
  }

  & .modalText {
    font-size: 0.875rem;
    line-height: 1.25rem;
    margin: 0;
    padding: 0;
  }

  & .buttonGroups {
    display: flex;
    align-items: center;
    gap: 15px;
    justify-content: space-between;
    margin-top: 40px;
  }

  & .cancelBtn {
    width: 8rem;
    min-height: 40px;
    border: 1px solid #000;
    background-color: #fff;
    color: #000;
    border-radius: 50px;
    font-weight: 400;
  }

  & .removeBtn {
    width: 8rem;
    min-height: 40px;
    border-radius: 50px;
    font-weight: 400;
  }
}

.desktopText {
  @media (max-width: 767px) {
    display: none;
  }
}

.professionalProfileInfo {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 26px 24px 17px;

  & .image {
    width: 82px;
    height: 82px;
    border-radius: 50%;

    @media (max-width: 767px) {
      width: 72px;
      height: 72px;
    }

    & .cardImage {
      width: 82px;
      height: 82px;
      border-radius: 50%;
      overflow: hidden;
      padding-top: 0;
      background-color: #ddd;
      display: flex;
      align-items: center;
      justify-content: center;

      @media (max-width: 767px) {
        width: 72px;
        height: 72px;
      }
    }
  }

  & .title {
    color: #000;
    font-family: 'Lato';
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    /* 377.778% */
    letter-spacing: 1px;
    margin: 0;
    padding: 0;
    text-transform: capitalize;
    color: #000000;

    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 22px;
    }
  }

  & .professionText {
    color: #000;
    font-family: 'Lato';
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    /* 523.077% */
    letter-spacing: 1px;
    text-transform: uppercase;

    margin: 1px 0 3px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;

    @media (max-width: 767px) {
      font-size: 11px;
    }
  }

  & .starIcons {
    & svg {
      width: 12px;
      height: 12px;
      margin-right: 2px;
    }
  }
}

.noImageIcon {
  padding-top: 0px;
  box-sizing: content-box;
  stroke: var(--colorGrey700);
}

.footerData {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.professionalContents {
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  flex: 1;

  & .description {
    padding: 0;
    font-family: Lato;
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    letter-spacing: 1px;

    color: #000000;
    margin: 0;
    min-height: 60px;
  }
}

.dividerLine {
  background: #bebaba;
  height: 1px;
  width: 100%;
  position: relative;
  margin: 23px 0 18px;
  margin-top: auto;

  &:before {
    content: '';
    height: 7px;
    width: 7px;
    background: #bebaba;
    border-radius: 100px;
    display: block;
    position: absolute;
    top: -3px;
  }

  &:after {
    content: '';
    height: 7px;
    width: 7px;
    background: #bebaba;
    border-radius: 100px;
    display: block;
    position: absolute;
    top: -3px;
    right: 0;
  }
}

.viewBtn {
  background-color: transparent;
  color: #000;
  text-align: center;
  font-family: 'Lato';
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 50px;
  letter-spacing: 1px;
  border: 1px solid #000;
  margin-top: 26px;
  min-height: 48px;
  margin-bottom: 22px;

  &:hover {
    background-color: #fff;
  }

  @media (max-width: 767px) {
    font-size: 14px;
    padding: 2px 18px;
    min-height: 36px;
    line-height: 30px;
  }
}

.jobCard {
  & .imageWrapper {
    /* border: 1px solid #b0a4a4c2; */
    height: 167px;
    border-radius: 8px;

    & img {
      height: 167px;
      object-fit: cover;
      border-radius: 8px;
      position: relative;
    }

    & .percentage {
      font-family: "Montserrat";
      font-size: 10px;
      font-weight: 700;
      line-height: 20px;

      color: #ffffff;
      background: #800080;
      width: fit-content;
      padding: 5px 10px;
      position: absolute;
      top: 0;
      border-radius: 8px 0;
    }

    & .wishListIcon {
      top: 6px;
      right: 8px;
      position: absolute;

      & svg {
        & path {
          fill: #C8C7CC;
        }
      }
    }

    & .likeIconWrapper {
      top: 6px;
      right: 8px;
      position: absolute;

      & svg {
        & path {
          fill: #ff0000;
        }
      }
    }
  }

  & .dealTitle {
    margin: 8px 0;
    padding: 0;
    font-family: Actor;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

    color: #000000;
  }

  & .dealType {
    font-family: "Montserrat";
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    color: #fff;
    background-color: #800080;
    border-radius: 45px;
    width: fit-content;
    padding: 3px 14px;
  }

  .priceWrapper {
    text-align: left;
    /* margin-top: 8px; */

    & .actualPrice {
      font-family: "Montserrat";
      font-size: 12px;
      font-weight: 500;
      line-height: 20px;
      font-style: normal;
      color: #ff4e40;
      text-decoration: line-through;
    }

    & .dealPrice {
      font-family: Actor;
      font-size: 15px;
      font-weight: 400;
      line-height: 20px;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;

      color: #8a8a8f;
      padding-left: 4px;
    }
  }
}

.dealCardWrapper {
  height: fit-content;

  & a {
    text-decoration: none;
  }

  list-style: none;
  width: 100%;
  flex-direction: column;
  background: #fff;
  box-shadow: none;
  padding: 0 !important;

  /* @media (max-width: 1260px) {
    width: calc(19% - 12px);
  }

  @media (max-width: 1023px) {
    width: calc(24% - 12px);
  }

  @media (max-width: 767px) {
    width: calc(30% - 0px);
  }

  @media (max-width: 600px) {
    width: calc(50% - 0px);
  }

  @media (max-width: 460px) {
    width: calc(100% - 0px);
  } */

  & a {
    width: 100%;

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.productCardWrapper {
  width: 100%;

  /* @media (max-width: 1260px) {
    width: calc((100% - 100px) / 3);
  }

  @media (max-width: 1023px) {
    width: calc((100% - 100px) / 3);
  }

  @media (max-width: 767px) {
    width: calc((100% - 50px) / 2);
  }

  @media (max-width: 460px) {
    width: calc(100% - 0px);
  } */

  & a {
    &:hover {
      text-decoration: none;
    }
  }
}

.authorName {
  margin: 0;
  /* font-family: SF Pro Text; */
  font-size: 13px;
  font-style: italic;
  font-weight: 400;
  line-height: 20px;

  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  padding: 0px 0 5px;
  margin-bottom: 0px;
  color: #8A8A8F;
}

& .reviewDetails {
  & .reviewNumber {
    font-family: Actor;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;

    text-underline-position: from-font;
    text-decoration-skip-ink: none;

    color: #8A8A8F;
    padding-left: 4px;
  }
}
.detailsWrapper{
  display: flex;
  gap: 8px;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 12px;
}
